<template>
  <div>
    <b-button
      type="is-primary"
      class="login-btn"
      icon-pack="fab"
      icon-left="google"
      @click="login"
    >
      Login
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'Login',
  methods: {
    login() {
      this.$auth.redirectToLoginForm(`${window.location.protocol}//${window.location.host}/`);
    },
  },
};
</script>

<style scoped lang="scss">
  div {
    display: flex;
    height: 100vh;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .login-btn {
      text-transform: uppercase;
      font-weight: 500;
    }
  }
</style>
